export default {
  pettycashbank: "hrmis/pettycash/pettycashbank",
  pettycash: "hrmis/pettycash",
  updatePettycash: "hrmis/pettycash/update",
  deletePettyCash: (param) => `hrmis/pettycash?${buildQuery(param)}`,
  pettycashlines: "hrmis/pettycash/line",
  deleteLines: "hrmis/pettycash/line/destroy",
  getByDocument: (param) => `hrmis/pettycash/show?document_no=${param}`,
  expenseCodes: "hrmis/imprest/expense_code",
  dimensions: "hrmis/imprest/dimensions",
  cancelApprovalRequest: "hrmis/pettycash/cancelApprovalRequest",
  sendForApproval: "hrmis/pettycash/sendForApproval",
  pettyCashDocument: (id) => `cu/ess_cu/pettyCashDocument?docNo=${id}`,
  DESTINATIONS: "hrmis/pettycash/destinations",
  TRANSPORT_RATES: "hrmis/pettycash/transportRates",
  funds: "hrmis/imprest/funds",
  dimensionSpeedKey: "hrmis/imprest/dimensionSpeedKey",
  checkPettyCashBudget: "hrmis/pettycash/checkPettyCashBudget",
};

function buildQuery(param) {
  const query = new URLSearchParams(param);
  return query.toString();
}
